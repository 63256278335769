<template>
  <v-col
    v-if="products && products.length"
    class="pl-4 pr-4 pt-2 justify-center"
  >
    <v-row justify="center" align="center" class="pa-0">
      <v-col sm="12" lg="11" xl="10" class="pa-0">
        <v-row justify="center" align="center" class="pa-0">
          <v-col
            class="custom5cols pa-0"
            v-for="product in products"
            :key="product._id"
          >
            <ProductCard
              :product="product"
            ></ProductCard> </v-col></v-row></v-col></v-row
  ></v-col>

  <div v-else class="no-results">{{ $t("no_results") }}</div>

  <v-row
    class="mt-10 mb-10"
    justify="center"
    v-if="
      shouldShowLoadMore && !hideLoadMore && products && products.length > 0
    "
  >
    <v-btn
      :loading="loadingMoreProducts"
      @click="loadMore"
      color="primary"
      class="button text-none"
      size="x-large"
    >
      {{ $t("load_more") }}
    </v-btn>
  </v-row>
</template>

<script>
import DiscountIcon from "../assets/svg/discount.vue";
import ProductCard from "./ProductCard.vue";
export default {
  name: "AllProducts",
  emits: ["loadMore"],
  components: {
    DiscountIcon,
    ProductCard,
  },
  props: {
    products: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: "Alla produkter",
    },
    description: {
      type: String,
      default: "",
    },
    hideLoadMore: {
      type: Boolean,
      default: false,
    },
    loadingMoreProducts: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    shouldShowLoadMore() {
      console.log(this.products.length % 50 === 0);
      return this.products.length % 50 === 0;
    },
  },
  methods: {
    loadMore() {
      this.$emit("loadMore");
    },
  },
};
</script>

<style scoped>
.hover {
  cursor: pointer;
}
.description-text {
  color: black;
  padding: 5px;
  font-weight: regular;
  font-size: 24px;
  z-index: 1;
  max-width: 1300px;
  margin: auto;
  padding-left: 25px;
}

.title-text {
  color: black;
  padding: 5px;
  font-weight: regular;
  font-size: 32px;
  z-index: 1;
  max-width: 1300px;
  margin: auto;
  padding-left: 25px;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(232px, 1fr));
  gap: 20px;
  max-width: 1300px;
  margin: 0 auto;
  margin-top: 0px;
  padding: 20px;
}

.product-item {
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f5f5f9;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 236px;
}

.product-image-container {
  position: relative;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.custom5cols {
  width: 20%;
  max-width: 200px;
  min-width: 170px;
  flex-basis: 20%;
}
.offer-tag {
  position: absolute;
  top: -35px;
  right: -40px;
  padding: 5px;
  border-radius: 50%;
  color: white;
  font-weight: bold;
}

.discount-icon {
  width: 135px;
  height: 100px;
  position: relative;
}

.product-image {
  width: 100%;
  height: 150px;
  margin-top: 20px;
  object-fit: contain;
}

.product-details {
  padding: 10px;
}

.product-name,
.product-weight,
.product-price {
  color: #333;
  margin: 4px 0;
  text-align: left;
}

.product-weight {
  color: #777;
}

.product-name {
  font-size: 18px;
}

.product-price {
  font-size: 22px;
  color: #c94631;
  font-weight: bold;
}

.add-to-cart-btn {
  background-color: #003a70;
  color: white;
  border: none;
  width: 95%;
  height: 50px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.add-to-cart-btn:hover {
  background-color: #005092;
}

.no-results {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  font-size: 24px;
  color: #666;
}

.load-more-container {
  max-width: 1300px;
  margin: auto;
  padding: 20px;
}

.load-more-btn {
  margin: auto;
  width: 225px;
  max-height: 35px;
  border-radius: 17.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 22px;

  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  height: 50px;
  background-color: #003a70;
  color: white;
}

.item-amount-container {
  display: flex;
  font-size: 28px;
  align-items: center;
  justify-content: center;
  background-color: #003a700f;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 5px;
  height: 50px;
}

.item-amount {
  font-size: 18px;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
}

.remove-cart,
.add-cart {
  margin: 5px;
  min-width: 35px;
  max-height: 35px;
  border-radius: 17.5px;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.remove-cart {
  border: solid;
  border-width: 1px;
  background-color: white;
}

.add-cart {
  height: 50px;
  background-color: #003a70;
  color: white;
}
</style>
