<template>
  <!-- Root template of the HomePage component -->
  <v-container class="pa-0" fluid>
    <Header />

    <!-- <SubHeader />
    <CategoryNavigation class="mt-4 pb-2" />
 Cart modal component -->

    <v-main class="mt-2">
      <router-view v-slot="{ Component }">
        <v-container
          class="pa-0 mt-3"
          style="min-height: 100vh; max-width: 100vw"
        >
          <v-col v-if="loadingStore" align="center" justify="center">
            <v-progress-circular color="primary" height="10" indeterminate />
          </v-col>
          <KeepAlive>
            <component :is="Component"> </component>
          </KeepAlive>
        </v-container>
      </router-view>
    </v-main>

    <v-footer color="primary" app absolute>
      <v-col class="pl-10 pr-10 pt-10 pb-0">
        <v-row justify="space-between">
          <v-col cols="12" md="4" class="pa-0 pb-4">
            <v-col class="pa-0" cols="6">
              <v-img :src="logo" contain class="hover" alt="ns-logo"></v-img>
            </v-col>
            <p class="body-2 mt-4">
              {{ $t("nearby_store_description") }}
            </p>
          </v-col>

          <v-col
            justify-self="center"
            align="center"
            class="text-start pa-0"
            cols="6"
            md="2"
          >
            <p class="bold-h3">{{ $t("quick_links") }}</p>
            <p class="hover body-2" @click="$router.push({ path: '/' })">
              {{ $t("home") }}
            </p>
            <p class="hover body-2" @click="$router.push({ name: 'handla' })">
              {{ $t("shop") }}
            </p>
            <p
              class="hover body-2"
              @click="$router.push({ name: 'howDoesItWork' })"
            >
              {{ $t("how_it_works") }}
            </p>
            <p
              class="hover body-2"
              @click="$router.push({ name: 'membership' })"
            >
              {{ $t("membership") }}
            </p>
            <p class="hover body-2" @click="goLocationModal">
              {{ $t("pickup_locations") }}
            </p>
          </v-col>
          <v-col class="pa-0" justify-self="center" cols="6" md="2">
            <p class="bold-h3">{{ $t("company") }}</p>
            <p
              class="hover body-2"
              @click="goToWebsite('https://nearbygroup.se/')"
            >
              {{ $t("about_us")
              }}<v-icon class="ml-2" size="20">mdi-open-in-new</v-icon>
            </p>
            <p
              class="hover body-2"
              @click="
                goToWebsite('https://nearbygroup.se/bli-en-del-av-teamet')
              "
            >
              {{ $t("career")
              }}<v-icon class="ml-2" size="20">mdi-open-in-new</v-icon>
            </p>
            <p
              class="hover body-2"
              @click="goToWebsite('https://nearbygroup.se/kontakta-oss')"
            >
              {{ $t("contact")
              }}<v-icon class="ml-2" size="20">mdi-open-in-new</v-icon>
            </p>
            <p
              class="hover body-2"
              @click="goToWebsite('https://nearbygroup.se/investerare')"
            >
              {{ $t("investor")
              }}<v-icon class="ml-2" size="20">mdi-open-in-new</v-icon>
            </p>
          </v-col>

          <v-col
            class="pa-0"
            align="start"
            cols="12"
            sm="2"
            v-if="!$vuetify.display.xs && !$vuetify.display.sm"
          >
            <p class="bold-h3">{{ $t("socials") }}</p>
            <p
              class="hover body-2"
              @click="
                goToWebsite('https://www.facebook.com/nearbystorese/?_rdr')
              "
            >
              <v-icon class="mr-2">mdi-facebook</v-icon>Facebook
            </p>
            <p
              class="hover body-2 mt-2"
              @click="goToWebsite('https://www.instagram.com/nearbystore.se/')"
            >
              <v-icon class="mr-2">mdi-instagram</v-icon>Instagram
            </p>
            <p
              class="hover body-2 mt-2"
              @click="
                goToWebsite('https://se.linkedin.com/company/nearbystore')
              "
            >
              <v-icon class="mr-2">mdi-linkedin</v-icon>Linkedin
            </p>
          </v-col>
          <v-row v-else class="mt-4" justify="space-between">
            <p
              class="hover body-2"
              @click="
                goToWebsite('https://www.facebook.com/nearbystorese/?_rdr')
              "
            >
              <v-icon class="mr-2">mdi-facebook</v-icon>Facebook
            </p>
            <p
              class="hover body-2"
              @click="goToWebsite('https://www.instagram.com/nearbystore.se/')"
            >
              <v-icon class="mr-2">mdi-instagram</v-icon>Instagram
            </p>
            <p
              class="hover body-2"
              @click="
                goToWebsite('https://se.linkedin.com/company/nearbystore')
              "
            >
              <v-icon class="mr-2">mdi-linkedin</v-icon>Linkedin
            </p></v-row
          >
        </v-row>
        <v-col class="mt-8">
          <v-row justify="center" class="ma-0">
            <p>© 2024 NearbyStore Sverige AB</p>
          </v-row>
          <v-row justify="center" class="pa-0 ma-0">
            <router-link
              :to="{ name: 'userterms' }"
              class="pr-1"
              style="color: white"
              >{{ $t("terms_of_service") }}</router-link
            >
            <router-link
              :to="{ name: 'privacypolicy' }"
              class="pl-1"
              style="color: white"
              >{{ $t("privacy_policy") }}</router-link
            >
          </v-row>
        </v-col>
      </v-col>
    </v-footer>
  </v-container>
</template>

<script>
// Component imports
import Header from "@/components/Header.vue";
import SubHeader from "@/components/SubHeader.vue";
import CategoryNavigation from "@/components/CategoryNavigation.vue";
import ProductDetailsModal from "@/components/ProductModal.vue";
import logo from "../assets/images/ns_white.png";
import store from "@/store";
import { mapActions, mapState, mapGetters } from "vuex";
export default {
  name: "HomePage",
  components: {
    Header,
    CategoryNavigation,
    ProductDetailsModal,
    SubHeader,
  },
  data() {
    return {
      loadingStore: false,
      logo: logo,
    };
  },
  computed: {
    storeId() {
      return store.state.location.storeId;
    },
  },
  watch: {
    storeId(newCount, oldCount) {
      console.log("changed storeID!!!");
      console.log(newCount);
      this.fetchStore();
    },
  },
  methods: {
    goToWebsite(url) {
      window.open(url, "_blank");
    },
    ...mapActions([
      "toggleLocationModal",
      "setCategoryModal",
      "toggleCartModal",
      "userLoggedOut",
    ]),
    async goLocationModal() {
      await this.$router.push({ name: "handla" });
      this.toggleLocationModal();
    },
    async fetchStore() {
      try {
        this.loadingStore = true;
        setTimeout(() => (this.loadingStore = false), 100);
      } catch (error) {
        this.loading = false;

        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.hover:hover {
  text-decoration: underline;
}
/* Styling for the grid parent */
.product-grid-container {
  margin-top: 100px; /* Default top margin */
  min-height: 100vh;
  display: flex;
  justify-content: center;
}

.product-parent-grid-container {
  max-width: none !important;
}

@media (min-width: 1024px) {
  .product-grid-container {
    margin-top: 142px; /* Default top margin */
    margin-left: 330px;
    width: calc(
      100% - 330px
    ) !important; /* Account the width of the CategoryNavigation to make the product grid fit perfectly */
    display: flex;
    justify-content: center;
  }
}
</style>
