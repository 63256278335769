<template>
  <v-row
    ><v-col>
      <v-col sm="12">
        <v-card
          color="primary"
          :class="
            !$vuetify.display.xs ? 'mr-10 ml-10 rounded-lg' : 'rounded-lg'
          "
          flat
        >
          <v-row cols="12" class="mr-4 ml-4 mt-10" justify="space-between">
            <v-col class="pa-0" cols="12" md="6" xl="4">
              <v-col align="start" class="pa-0 mr-4 ml-4">
                <span class="bold-title" style="color: #0082ca">
                  <span style="color: #f5f5f9"> {{ $t("linkoping") }}</span
                  ><br />
                  <span style="color: #8ab7e9"> {{ $t("closest") }}</span
                  ><br />
                  <span style="color: #f5f5f9"> {{ $t("supermarket") }}</span>
                </span>
              </v-col>
              <v-col align="start" class="mt-4 mr-4 ml-4">
                <p class="body-1">
                  {{ $t("shop_smart") }}
                </p>
              </v-col>
              <v-col align="start" class="mt-4 mb-10 pa-0 ma-0">
                <v-btn
                  flat
                  size="x-large"
                  color="error"
                  @click="goLocationModal"
                  :class="
                    !$vuetify.display.xs
                      ? 'mr-4 ml-4 text-none button'
                      : 'text-none button'
                  "
                >
                  <v-icon class="mr-2">mdi-map-marker</v-icon
                  >{{ $t("see_pickup_locations_near_me") }}</v-btn
                >
              </v-col>
            </v-col>
            <v-col class="d-flex justify-end align-end" cols="12" md="5" xl="6">
              <v-img :src="bag2" contain alt="ns-bag"></v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col>
        <v-row justify="center" align="center" class="mt-6">
          <v-col cols="12" sm="8" justify="center" align="center">
            <p class="bold-h1">{{ $t("how_does_works") }}</p>
            <p class="body-2 mt-4">
              {{ $t("ns_description") }}
            </p>
          </v-col></v-row
        >
        <v-row justify="space-between" class="mt-8">
          <v-col align="center" cols="12" sm="3">
            <v-icon size="35" color="primary"> mdi-cart </v-icon>
            <p class="bold-h3">{{ $t("step_1") }}</p>

            <p class="body-2 mt-2">{{ $t("order_in_app_or_web") }}</p>
          </v-col>
          <v-col align="center" cols="12" sm="3">
            <v-icon size="35" color="primary"> mdi-map-marker </v-icon>

            <p class="bold-h3">{{ $t("step_2") }}</p>
            <p class="body-2 mt-2">{{ $t("choose_pickup_time_location") }}</p>
          </v-col>
          <v-col align="center" cols="12" sm="3">
            <v-icon size="35" color="primary"> mdi-forklift </v-icon>
            <p class="bold-h3">{{ $t("step_3") }}</p>
            <p class="body-2 mt-2">
              {{ $t("we_pick_order_ready_in_hours") }}
            </p>
          </v-col>
          <v-col align="center" cols="12" sm="3">
            <v-icon size="35" color="primary"> mdi-flag-checkered </v-icon>
            <p class="bold-h3">{{ $t("step_4") }}</p>
            <p class="body-2 mt-2">{{ $t("collect_items_pickup_location") }}</p>
          </v-col>
        </v-row>
      </v-col>
      <v-row class="mt-10 ml-10 mr-10" justify="center">
        <p class="bold-h1" align="center">
          {{ $t("benefits_of_nearbystore") }}
        </p>
      </v-row>
      <v-row class="mr-10 mt-10 ml-10" justify="space-evenly">
        <v-col cols="12" sm="5" class="pa-1">
          <v-card class="rounded-lg" flat color="background">
            <v-img
              :src="locker"
              cover
              class="rounded-xl"
              alt="ns-locker"
            ></v-img
          ></v-card> </v-col
        ><v-col
          cols="12"
          sm="5"
          class="d-flex flex-column justify-space-evenly pa-0"
        >
          <v-row align="center" class="mt-2">
            <v-col cols="2" sm="1" class="pa-0">
              <v-card class="pt-2 pb-2" color="secondary" flat align="center"
                ><v-icon size="25" color="primary">mdi-truck</v-icon></v-card
              >
            </v-col>
            <v-col class="pa-2">
              <p class="bold-h4 pb-2">{{ $t("low_shipping_costs") }}</p>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="2" sm="1" class="pa-0">
              <v-card class="pt-2 pb-2" color="secondary" flat align="center"
                ><v-icon size="25" color="primary">mdi-package</v-icon></v-card
              >
            </v-col>
            <v-col class="pa-2">
              <p class="bold-h4 pb-2">{{ $t("same_day_pickup") }}</p>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="2" sm="1" class="pa-0">
              <v-card class="pt-2 pb-2" color="secondary" flat align="center"
                ><v-icon size="25" color="primary">mdi-gift</v-icon></v-card
              >
            </v-col>
            <v-col class="pa-2">
              <p class="bold-h4 pb-2">
                {{ $t("free_shipping_bonus") }}
              </p>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="2" sm="1" class="pa-0">
              <v-card class="pt-2 pb-2" color="secondary" flat align="center"
                ><v-icon size="25" color="primary">mdi-trophy</v-icon></v-card
              >
            </v-col>
            <v-col class="pa-2">
              <p class="bold-h4 pb-2">{{ $t("no_minimum_purchase_fees") }}</p>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="2" sm="1" class="pa-0">
              <v-card class="pt-2 pb-2" color="secondary" flat align="center"
                ><v-icon size="25" color="primary"
                  >mdi-information</v-icon
                ></v-card
              >
            </v-col>
            <v-col class="pa-2">
              <p class="bold-h4 pb-2">{{ $t("wide_selection_low_prices") }}</p>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="2" sm="1" class="pa-0">
              <v-card class="pt-2 pb-2" color="secondary" flat align="center"
                ><v-icon size="25" color="primary">mdi-trophy</v-icon></v-card
              >
            </v-col>
            <v-col class="pa-2">
              <p class="bold-h4 pb-2">
                {{ $t("extra_low_prices_for_members") }}
              </p>
            </v-col>
          </v-row>
          <v-row align="center" class="pl-0 mt-8">
            <v-btn
              flat
              block
              color="error"
              :to="{ name: 'handla' }"
              class="button text-none"
              size="x-large"
              >{{ $t("shop_now") }}</v-btn
            >
          </v-row>
        </v-col>
      </v-row>
      <v-row
        :class="!$vuetify.display.xs ? 'mr-14 ml-14 mt-14' : 'mt-14 ml-2 mr-2'"
      >
        <v-col cols="12" md="6">
          <v-card
            color="primary rounded-lg"
            class="pa-10"
            flat
            style="height: 100%"
          >
            <v-col class="pa-0">
              <p class="bold-h1">{{ $t("membership") }}</p>
              <p class="body-2 mt-4">
                {{ $t("become_a_member") }}
              </p>
            </v-col>
            <v-row class="pa-0" align="center">
              <v-col justify="center">
                <v-col class="pt-4 pb-0">
                  <v-row align="center">
                    <v-icon size="25" color="surface">mdi-check-circle</v-icon>

                    <v-col class="pa-2 ml-2">
                      <p class="bold-h4 pb-2">
                        {{ $t("always_free_shipping") }}
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="pt-0 pb-0">
                  <v-row align="center">
                    <v-icon size="25" color="surface">mdi-check-circle</v-icon>

                    <v-col class="pa-2 ml-2">
                      <p class="bold-h4 pb-2">
                        {{ $t("bonuses_on_all_purchases") }}
                      </p>
                    </v-col>
                  </v-row></v-col
                >
                <v-col class="pt-0 pb-1">
                  <v-row align="center">
                    <v-icon size="25" color="surface">mdi-check-circle</v-icon>

                    <v-col class="pa-2 ml-2">
                      <p class="bold-h4 pb-2">
                        {{ $t("special_discounts_for_members") }}
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-col>
              <v-col
                v-if="!$vuetify.display.xs"
                class="d-flex justify-end align-end"
                cols="5"
                lg="6"
                xl="5"
              >
                <v-img :src="star" contain alt="star-icon"></v-img>
              </v-col>
            </v-row>
          </v-card> </v-col
        ><v-col>
          <v-card
            color="primary rounded-lg"
            class="pa-0"
            flat
            style="height: 100%"
          >
            <v-col class="pt-10 pl-10 pr-10">
              <p class="bold-h1">
                {{ $t("what_does_it_cost_to_be_a_member") }}
              </p>
              <p class="body-2 mt-4">
                {{ $t("membership_cost_details") }}
              </p>
            </v-col>
            <v-row justify="space-between" align="center" class="pl-10">
              <v-btn
                color="error"
                class="button text-none mt-4 mb-8"
                size="x-large"
                flat
                @click="$router.push({ name: 'membership' })"
              >
                {{ $t("become_member_now")
                }}<v-icon class="pt-1" size="18">mdi-chevron-right</v-icon>
              </v-btn>
              <v-col
                v-if="!$vuetify.display.xs"
                class="d-flex justify-end align-end"
                cols="4"
                md="5"
                xl="4"
              >
                <v-img :src="food_half" contain alt="food_splash"></v-img>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-col class="mb-10">
        <v-row justify="center" class="mt-10">
          <v-col align="center" cols="12" sm="7">
            <p class="bold-h1">{{ $t("faq") }}</p>
          </v-col>
        </v-row>
        <v-row justify="space-evenly" class="mt-8 ml-10 mr-10">
          <v-col align="center" cols="12" md="4">
            <p class="bold-h3">{{ $t("why_low_prices") }}</p>

            <ul class="body-2 mt-2" style="text-align: start">
              <li class="pl-2">
                {{ $t("less_shipping") }}
              </li>
              <li class="mt-2 pl-2">
                {{ $t("fixed_time_windows") }}
              </li>
              <li class="mt-2 pl-2">
                {{ $t("better_planning") }}
              </li>
            </ul>
          </v-col>
          <v-col align="center" cols="12" md="4">
            <p class="bold-h3">{{ $t("when_in_my_area") }}</p>
            <p class="body-2 mt-2">{{ $t("growing_network") }}</p>
          </v-col>
          <v-col align="center" cols="12" md="4">
            <p class="bold-h3">{{ $t("when_can_pickup") }}</p>
            <p class="body-2 mt-2">
              {{ $t("see_pickup_times") }}
              <a
                href="https://nearbystore.se/handla"
                @click="goLocationModal"
                class="pr-1 hover"
                style="color: black; text-decoration: underline"
                >{{ $t("here") + "." }}</a
              >
              {{ $t("fast_pickup_info") }}
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-row justify="center" class="ml-10 mr-10">
        <v-col align="center" cols="12" sm="2" class="mt-0 mb-15">
          <v-btn
            flat
            block
            color="error"
            :to="{ name: 'handla' }"
            class="button text-none"
            size="x-large"
            >{{ $t("shop_now") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-col></v-row
  >
</template>

<script lang="ts">
import bag2 from "../assets/images/bag.png";
import locker from "../assets/images/ns-locker.png";
import food_half from "../assets/images/food_half.png";
import star from "../assets/images/star.png";
import { mapActions, mapState, mapGetters } from "vuex";
import { useHead } from "@unhead/vue";
export default {
  data() {
    return {
      locker: locker,
      bag2: bag2,
      food_half: food_half,
      star: star,
    };
  },
  activated() {
    useHead({
      title: "NearbyStore | Matbutiken online, med upphämtning nära dig",
      htmlAttrs: {
        lang: "sv",
      },
      meta: [
        { charset: "UTF-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1.0" },
        {
          hid: "description",
          name: "description",
          content:
            "NearbyStore är en matbutik på nätet där du hämtar maten direkt, nära dig. Med vårt nätverk av små kompakta automatiserade lager och upphämtningsställen kan vi drastiskt minska lokalytan, matsvinnet, energikostnaderna och effektivare använda personalstyrkan. Det gör att du som kund får en mer flexibel onlinehandel och kan handla från ett stort sortiment med låga priser dygnet runt.",
        },
        {
          name: "keywords",
          content:
            "nearby, store, matbutik, livsmedelbutik, linköping, mathem, mat, hem, ",
        },
        { name: "author", content: "Nearbystore Sverige AB" },
        {
          name: "apple-itunes-app",
          content: "app-id=1593224780, app-argument=https://nearbystore.se/",
        },
      ],
    });
  },
  methods: {
    ...mapActions([
      "toggleLocationModal",
      "setCategoryModal",
      "toggleCartModal",
      "userLoggedOut",
    ]),
    async goLocationModal() {
      await this.$router.push({ name: "handla" });
      this.toggleLocationModal();
    },
  },
  async mounted() {},
};
</script>
<style>
.hover {
  cursor: pointer;
}
:deep(.v-breadcrumbs-divider) {
  padding: 0;
}
@media only screen and (max-width: 800px) {
  .button {
    font-size: medium;

    font-family: "agenda-light", Arial, sans-serif;
  }
  .xl-button {
    font-size: large;

    font-family: "agenda-light", Arial, sans-serif;
  }
  .bold-title {
    font-size: 2.5rem;
    font-weight: 500;
    font-family: "serenity-bold", "agenda-light", Arial, sans-serif;
  }
  .bold-h1 {
    font-size: 2.125rem;
    font-weight: 500;
    line-height: 30pt;
    font-family: "serenity-bold", "agenda-light", Arial, sans-serif;
  }
  .bold-h2 {
    font-size: 1.75rem;
    font-weight: 500;
    font-family: "serenity-bold", "agenda-light", Arial, sans-serif;
  }
  .bold-h3 {
    font-size: 1.25rem;
    font-weight: 500;
    font-family: "serenity-bold", "agenda-light", Arial, sans-serif;
  }
  .bold-h4 {
    font-size: 1rem;
    font-weight: 500;
    font-family: "serenity-bold", "agenda-light", Arial, sans-serif;
  }
  .bold-1 {
    font-weight: 500;
    font-family: "serenity-bold", "agenda-light", Arial, sans-serif;
  }
  .bold-2 {
    font-weight: 500;
    font-size: small;

    font-family: "serenity-bold", "agenda-light", Arial, sans-serif;
  }
  .body-1 {
    font-weight: 500;
    font-size: large;
    font-family: "agenda-light", Arial, sans-serif;
  }
  .body-2 {
    font-weight: 500;
    font-size: medium;
    font-family: "agenda-light", Arial, sans-serif;
  }
  .body-3 {
    font-weight: 500;
    font-size: small;
    font-family: "agenda-light", Arial, sans-serif;
  }
  .body-4 {
    font-weight: 500;
    font-size: x-small;
    font-family: "agenda-light", Arial, sans-serif;
  }
}

@media only screen and (min-width: 801px) {
  .xs-button {
    font-size: small;
    font-family: "agenda-light", Arial, sans-serif;
  }
  .button {
    font-size: medium;
    font-family: "agenda-light", Arial, sans-serif;
  }
  .xl-button {
    font-size: large;

    font-family: "agenda-light", Arial, sans-serif;
  }
  .bold-title {
    font-size: 4.5rem;
    font-weight: 500;
    line-height: 45pt;
    font-family: "serenity-bold", "agenda-light", Arial, sans-serif;
  }
  .bold-h1 {
    font-size: 2.75rem;
    font-weight: 500;
    line-height: 35pt;
    font-family: "serenity-bold", "agenda-light", Arial, sans-serif;
  }
  .bold-h2 {
    font-size: 2.125rem;
    font-weight: 500;
    font-family: "serenity-bold", "agenda-light", Arial, sans-serif;
  }
  .bold-h3 {
    font-size: 1.75rem;
    font-weight: 500;
    font-family: "serenity-bold", "agenda-light", Arial, sans-serif;
  }
  .bold-promo {
    font-size: 1.5rem;
    font-weight: 500;
    font-family: "serenity-bold", "agenda-light", Arial, sans-serif;
  }
  .bold-h4 {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 15pt;
    font-family: "serenity-bold", "agenda-light", Arial, sans-serif;
  }
  .bold-1 {
    font-weight: 500;

    font-family: "serenity-bold", "agenda-light", Arial, sans-serif;
  }
  .bold-2 {
    font-weight: 500;
    font-size: small;
    font-family: "serenity-bold", "agenda-light", Arial, sans-serif;
  }
  .body-1 {
    font-weight: 500;
    font-size: x-large;
    font-family: "agenda-light", Arial, sans-serif;
  }
  .body-2 {
    font-weight: 500;
    font-size: large;
    font-family: "agenda-light", Arial, sans-serif;
  }
  .body-3 {
    font-weight: 500;
    font-size: medium;
    font-family: "agenda-light", Arial, sans-serif;
  }
  .body-4 {
    font-weight: 500;
    font-size: small;
    font-family: "agenda-light", Arial, sans-serif;
  }
}
</style>
