// store/modules/vtoast.js
const state = {
  visible: false,
  message: "",
  color: "success",
  timer: 3000,
  icon: undefined,
};

const mutations = {
  SHOW_TOAST(state, { message, color, timer, icon }) {
    state.visible = true;
    state.message = message;
    state.color = color;
    state.timer = timer;
    state.icon = icon;
    console.log(state);
  },
  HIDE_TOAST(state) {
    state.visible = false;
  },
};

const actions = {
  showToast({ commit }, { message, color = "success", timer = 3000, icon }) {
    commit("SHOW_TOAST", { message, color, timer, icon });
    setTimeout(() => {
      commit("HIDE_TOAST");
    }, timer);
  },
  hideToast({ commit }) {
    commit("HIDE_TOAST");
  },
};

const getters = {
  isToastVisible: (state) => state.visible,
  toastMessage: (state) => state.message,
  toastColor: (state) => state.color,
  toastTimer: (state) => state.timer,
  toastIcon: (state) => state.icon,
};

export default {
  namespaced: true, // This ensures the module is properly scoped
  state,
  mutations,
  actions,
  getters,
};
