<template>
  <v-col>
    <div class="bold-h1 mb-10">{{ $t("recipes") }}</div>
    <v-row v-if="loading" align="center" justify="center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular
    ></v-row>
    <v-col
      v-else-if="recipes && recipes.length"
      class="pl-4 pr-4 pt-2 justify-center"
    >
      <v-row justify="center" align="center" class="pa-0">
        <v-col cols="12" xl="10">
          <v-row justify="center" align="center" class="pa-0">
            <v-col
              class="custom5cols pa-2"
              v-for="recipe in recipes"
              :key="recipe._id"
            >
              <v-card style="height: 325px" elevation="0"
                ><v-col class="pa-0">
                  <v-col
                    align="center"
                    justify="center"
                    class="pa-0"
                    style="position: relative"
                  >
                    <div
                      style="
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        z-index: 1000;
                      "
                    >
                      <LikedButton :recipe="recipe" />
                    </div>
                    <v-row
                      style="
                        position: absolute;
                        bottom: 3px;
                        right: 0px;
                        z-index: 1000;
                      "
                      class="pa-4"
                      ><v-card
                        elevation="0"
                        class="pa-1 rounded-lg"
                        :color="getColor(recipe.difficulty)"
                        style="padding: 1px"
                        ><p class="pb-1 bold-2">
                          {{ recipe.difficulty }}
                        </p></v-card
                      ><v-card class="ml-4 pa-1 rounded-lg" elevation="0"
                        ><p class="pb-1 bold-2">
                          {{ recipe.time + " min" }}
                        </p></v-card
                      ></v-row
                    >
                    <v-img
                      v-if="recipe.image"
                      :src="recipe.image.url"
                      @click="goToRecipe(recipe)"
                      width="100%"
                      cover
                      height="250"
                      class="hover"
                      alt="Recipe Image"
                    />
                  </v-col>

                  <v-col align="center">
                    <p class="bold-h4">
                      {{ recipe.name }}
                    </p>
                  </v-col>
                </v-col></v-card
              >
            </v-col></v-row
          ></v-col
        ></v-row
      ></v-col
    >
  </v-col>
</template>
<script>
import AllProducts from "../components/AllProducts.vue";
import RecipeAPI from "@/api/RecipeAPI";
import { mapState } from "vuex";
import LikedButton from "@/components/LikedButton.vue";
import { useHead } from "@unhead/vue";
export default {
  name: "Recipe",
  components: {
    LikedButton,
  },

  data() {
    return {
      recipes: [],
      loading: true,
      page: 1,
    };
  },
  activated() {
    useHead({
      title: "Recept - NearbyStore",
      meta: [
        {
          hid: "description",
          name: "description",
          content: "Recept på NearbyStore",
        },
      ],
      __key: "recipeHead",
    });
  },
  async mounted() {
    console.log("loading");
    await this.fetchRecipes();
    this.loading = false;
  },
  watch: {
    currentLang(newVal) {
      this.translateProducts();
    },
  },
  methods: {
    getColor(name) {
      switch (name) {
        case "Lätt":
          return "success";
        case "Medel":
          return "warning";
        case "Svårt":
          return "error";
        default:
          return "success";
      }
    },
    goToRecipe(recipe) {
      this.$router.push({
        name: "recipeDetail",
        params: {
          id: recipe._id,
        },
      });
    },
    async fetchRecipes() {
      try {
        const response = await RecipeAPI.fetchRecipes({
          page: this.page,
        });
        this.recipes = response.data;
        console.log(response);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },
  },
};
</script>
<style scoped>
.custom5cols {
  max-width: 300px;
  min-width: 275px;
}
</style>
