<template>
  <v-col>
    <!-- Vuetify Loading Component -->

    <!-- Content to display when not loading -->
    <div>
      <!-- Display SubcategoryNavigation on phone or tablet -->
      <SubcategoryNavigation
        v-if="isPhone || isTablet"
        :parentId="$route.params.categoryName"
      />
      <Breadcrumbs :items="breadcrumbItems" />

      <v-col class="pa-0">
        <div class="bold-h1 mb-10">{{ title }}</div>

        <v-row v-if="loading" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>
        <AllProducts
          v-else
          :products="products"
          @loadMore="handleLoadMore"
          :loadingMoreProducts="loadingMoreProducts"
        />
      </v-col>
    </div>
  </v-col>
</template>
<script>
import AllProducts from "../AllProducts.vue";
import CategoryProductAPI from "@/api/CategoryProductAPI";
import { useI18n } from "vue-i18n";
import { mapFields } from "vuex-map-fields";
import { mapState } from "vuex";
import SubcategoryNavigation from "@/components/smallDevicesOnly/SubCategoryNavigation.vue";
import Breadcrumbs from "../Breadcrumbs.vue";
import { useHead } from "@unhead/vue";
/**
 * Component for displaying search results.
 * It fetches and displays products based on the search term from the route parameter.
 * It also redirects to the homepage if the storeId is undefined and reloads the API requests if the storeId changes.
 */
export default {
  name: "SearchResults",

  components: {
    SubcategoryNavigation,
    AllProducts,
    Breadcrumbs,
  },
  data() {
    return {
      products: [],
      page: 1,

      loadingMoreProducts: false,
      loading: true,
      category: null,
      subCategory: null,
      categoryid: undefined,
      subcategoryid: undefined,
    };
  },
  computed: {
    ...mapState(["currentLang", "categories"]),
    title() {
      return this.subCategory != null
        ? this.subCategory.category.name
        : this.category != null
        ? this.category.category.name
        : "";
    },
    breadcrumbItems() {
      return [
        {
          text: this.t("home"),
          to: {
            name: "handla",
          },
        },
        ...(this.category != null
          ? [
              {
                text: this.category.category.name,
                disabled: this.subCategory == null,
                to: {
                  name: "category",
                  params: {
                    categoryName: this.category.category._id,
                  },
                },
              },
            ]
          : []),
        ...(this.subCategory != null
          ? [{ text: this.subCategory.category.name, disabled: true }]
          : []),
      ];
    },
    isPhone() {
      return window.innerWidth <= 600;
    },
    isTablet() {
      return window.innerWidth > 600 && window.innerWidth <= 1024;
    },
  },
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  watch: {
    $route: async function (newRoute, oldRoute) {
      console.log(newRoute.params.categoryName);
      console.log(newRoute.params.subcategoryName);

      console.log(this.categoryid);
      console.log(this.subcategoryid);
      if (
        (newRoute.name == "category" && oldRoute.name != "ProductDetail") ||
        (newRoute.params.categoryName != undefined &&
          (this.categoryid != newRoute.params.categoryName ||
            this.subcategoryid != newRoute.params.subcategoryName))
      ) {
        this.loading = true;
        this.products = [];
        this.page = 1;
        this.setCategories();
        await this.fetchProducts();
        this.loading = false;
      }
    },
    currentLang(newVal) {
      this.translateProducts();
    },
    categories() {
      this.setCategories();
    },
  },
  methods: {
    setCategories() {
      const category = this.categories.find(
        (cat) => cat.category._id === this.$route.params.categoryName
      );
      this.category = category ? category : null;
      const subcatId = `${this.$route.params.categoryName}.${this.$route.params.subcategoryName}`;
      const subcategory = this.categories.find(
        (cat) => cat.category._id === subcatId
      );
      this.subCategory = subcategory ? subcategory : null;
      this.categoryid = this.$route.params.categoryName;
      this.subcategoryid =
        this.$route.params.subcategoryName == "" ||
        this.$route.params.subcategoryName == undefined
          ? ""
          : this.$route.params.subcategoryName;
      this.setHead();
    },
    async translateProducts() {
      try {
        const limit = this.products.length;
        const response = await CategoryProductAPI.getProducts({
          category: this.getCategoryParam(),
          limit: limit,
        });
        this.products = response.data;
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },
    setHead() {
      const categoryTitle = `${this.title} - NearbyStore`;

      useHead({
        title: categoryTitle,
        meta: [
          {
            hid: "description",
            name: "description",
            content:
              "Beställ idag på NearbyStore och få din mat levererad samma dag. Upptäck ett brett sortiment av färska råvaror och livsmedel, med bekväm hämtning nära dig. Shoppa enkelt och snabbt!",
          },
        ],
        __key: "categoryHead",
      });
    },
    async fetchProducts() {
      try {
        const response = await CategoryProductAPI.getProducts({
          category: this.getCategoryParam(),
          page: this.page,
        });
        this.products = [...this.products, ...response.data];
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },
    async handleLoadMore() {
      this.loadingMoreProducts = true;
      this.page += 1;
      await this.fetchProducts();
      this.loadingMoreProducts = false;
    },
    getCategoryParam() {
      return this.subCategory != null
        ? this.subCategory.category._id
        : this.category.category._id;
    },
    checkDevice() {
      const width = window.innerWidth;
      this.isPhone = width <= 600;
      this.isTablet = width > 600 && width <= 1024;
    },
  },
  async mounted() {
    window.addEventListener("resize", this.checkDevice);
    this.setCategories();
    await this.fetchProducts();
    this.loading = false;
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkDevice);
  },
};
</script>
<!--
<script>
import AllProducts from "../AllProducts.vue";
import SubcategoryNavigation from "@/components/smallDevicesOnly/SubCategoryNavigation.vue";
import CategoryProductAPI from "@/api/CategoryProductAPI";
import { ref, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
/**
 * A Vue component for displaying products based on category selections.
 * Uses CategoryProductAPI to fetch products and handles pagination through a "Load More" mechanism.
 */
export default {
  name: "CategoryProducts",
  components: {
    AllProducts,
    SubcategoryNavigation,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const products = ref([]); // Stores the list of products
    const productLimit = ref(50); // Initial number of products to display
    const loading = ref(true); // Tracks if the data is still loading
    const isPhone = ref(false);
    const isTablet = ref(false);
    const categoryName = ref("");
    const subcategoryName = ref("");
    const categoryId = ref("");
    const subcategoryId = ref("");
    const { t, locale } = useI18n();
    // Checks and redirects to homepage if storeId is not defined
    if (!store.state.location.storeId) {
      router.push("/");
      return;
    }

    const goBreadcrumb = () => {};
 
     * Fetches products based on the current category or subcategory.
     * Uses the CategoryProductAPI for data fetching.
     */
    const fetchProducts = async () => {
      const categoryParam = getCategoryParam();
      try {
        loading.value = true;
        const response = await CategoryProductAPI.getProducts({
          category: categoryParam,
          limit: productLimit.value,
        });
        products.value = response.data;
        loading.value = false; // Set loading to false once data is fetched
      } catch (error) {
        console.error("Error fetching products:", error);
        loading.value = false; // Set loading to false even if there is an error
      }
    };

    /**
     * Increases the product limit to fetch more products.
     * Invoked when the "Load More" button is clicked.
     */
    const handleLoadMore = () => {
      productLimit.value += 50;
      fetchProducts();
    };

    /**
     * Derives the appropriate category parameter for API requests.
     * Supports both main categories and subcategories.
     * @returns {String} The category parameter for API requests.
     */
    const getCategoryParam = () => {
      return route.params.subcategoryName
        ? `${route.params.categoryName}.${route.params.subcategoryName}`
        : route.params.categoryName;
    };

    // Watch for route parameter changes to refetch products
    watch(
      () => store.state.currentLang,
      async () => {
        console.log("lang changed...!");

        const categoryParam = getCategoryParam();
        try {
          const response = await CategoryProductAPI.getProducts({
            category: categoryParam,
            limit: productLimit.value,
          });
          products.value = response.data;
        } catch (error) {
          console.error("Error fetching products:", error);
        }
      }
    );
    watch(
      () => route.params,
      () => {
        console.log(route.params);
        console.log(categoryId.value);
        console.log(subcategoryId.value);
        if (
          (route.params.subcategoryName !== undefined ||
            route.params.categoryName !== undefined) &&
          (categoryId.value !== route.params.categoryName ||
            subcategoryId.value !== route.params.subcategoryName)
        ) {
          console.log("fetching...");
          fetchProducts();
          categoryId.value = route.params.categoryName;
          const category = store.getters.categories.find(
            (cat) => cat.category._id === categoryId.value
          );
          categoryName.value = category
            ? category.category.name
            : "Unknown Category";
          subcategoryId.value = route.params.subcategoryName;

          const subcatId = `${categoryId.value}.${route.params.subcategoryName}`;
          const subcategory = store.getters.categories.find(
            (cat) => cat.category._id === subcatId
          );
          subcategoryName.value = subcategory
            ? subcategory.category.name
            : null;
        }
      },
      { immediate: true }
    );
    /**
     * Computes breadcrumb items for the category page.
     * Includes a link to the home page and category/subcategory names.
     * @returns {Array} An array of breadcrumb item objects.
     */
    const breadcrumbItems = computed(() => [
      {
        text: t("home"),
        to: {
          name: "handla",
        },
      },
      {
        text: categoryName.value,
        to: {
          name: "category",
          params: {
            categoryName: categoryId.value,
          },
        },
      },
      ...(subcategoryName.value
        ? [{ text: subcategoryName.value, disabled: true }]
        : []),
    ]);

    /**
     * Determines the title to be displayed on the category page.
     * Uses either the subcategory name or the category name.
     * @returns {String} The title for the category page.
     */
    const title = computed(() => subcategoryName.value || categoryName.value);

    const checkDevice = () => {
      const width = window.innerWidth;
      isPhone.value = width <= 600;
      isTablet.value = width > 600 && width <= 1024;
    };

    // Initialize device check
    checkDevice();
    window.addEventListener("resize", checkDevice);

    return {
      products,
      productLimit,
      handleLoadMore,
      breadcrumbItems,
      title,
      loading,
      getCategoryParam,
      route,
      isPhone,
      isTablet,
    };
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkDevice);
  },
};
</script>
-->
<style scoped>
:deep(.v-breadcrumbs-divider) {
  padding: 0;
}
/* Styling for breadcrumb navigation */
.breadcrumbs {
  max-width: 1300px;
  margin: auto;
  padding-left: 20px;
}
</style>
