<template>
  <v-col>
    <div class="bold-h1 mb-10">{{ $t("offers") }}</div>
    <v-row v-if="loading" align="center" justify="center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular
    ></v-row>

    <AllProducts v-else :products="allProducts" @loadMore="handleLoadMore" />
  </v-col>
</template>

<script>
import AllProducts from "../components/AllProducts.vue";
import CategoryProductAPI from "@/api/CategoryProductAPI";
import { mapState } from "vuex";
import { useHead } from "@unhead/vue";
export default {
  name: "Offer",
  emits: ["loadMore"],
  components: {
    AllProducts,
  },
  data() {
    return {
      allProducts: [],
      loading: true,
      page: 1,
    };
  },
  activated() {
    useHead({
      title: "Erbjudanden på NearbyStore",
      meta: [
        {
          hid: "description",
          name: "description",
          content: "Nuvarande erbjudanden hos NearbyStore",
        },
      ],
      __key: "recipeHead",
    });
  },
  computed: {
    ...mapState(["currentLang"]),
  },
  async mounted() {
    console.log("loading");
    await this.fetchProducts();
    this.loading = false;
  },
  watch: {
    currentLang(newVal) {
      this.translateProducts();
    },
  },
  methods: {
    async translateProducts() {
      try {
        const limit = this.allProducts.length;
        const response = await CategoryProductAPI.getProducts({
          offers: true,
          limit: limit,
        });
        this.allProducts = response.data;
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },
    async fetchProducts() {
      try {
        const response = await CategoryProductAPI.getProducts({
          page: this.page,
          offers: true,
        });
        this.allProducts = [...this.allProducts, ...response.data];
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },

    handleLoadMore() {
      this.page += 1;
      this.fetchProducts();
    },
  },
};
</script>

<style scoped>
.grid-container {
  width: 100%;
  margin-bottom: 125px;
  position: relative;
}

.phone-wrapper {
  width: 95%;
  margin: auto;
}
</style>
