import {
  RouteLocationNormalized,
  createRouter,
  createWebHistory,
} from "vue-router";
import store from "@/store"; // Import the Vuex store
import HomePage from "@/screens/HomePage.vue";
import ProductDetailsModal from "@/components/ProductModal.vue";
import ProductDetailsSingle from "@/components/ProductSingle.vue";
import LoginPage from "@/screens/Login.vue";
import LoginPageModal from "@/screens/LoginModal.vue";
import SignInPage from "@/screens/SignIn.vue";
import SignUpPage from "@/screens/SignUp.vue";
import UserTerms from "@/screens/UserTerms.vue";
import PrivacyPolicy from "@/screens/PrivacyPolicy.vue";
import CartPage from "@/screens/Cart.vue"; // Import the CheckoutPage component
import OrdersPage from "@/screens/Orders.vue"; // Import the CheckoutPage component
import OrderPage from "@/screens/Order.vue";
import StripePage from "@/screens/payment/stripe/Stripe.vue"; // Import the StripePage component
import CheckoutPage from "@/screens/payment/Checkout.vue"; // Import the SwishPage component
import BannerProducts from "@/components/resultComponents/Banner.vue";
import FeaturedProductsProducts from "@/components/resultComponents/FeaturedProducts.vue";
import SearchResultsProducts from "@/components/resultComponents/SearchResults.vue";
import CategoryProducts from "@/components/resultComponents/Category.vue";
import ProductGrid from "@/components/ProductGrid.vue";
import CategoryNavigationScreen from "@/components/smallDevicesOnly/CategoryNavigationScreen.vue";
import routerView from "@/components/router.vue";
import SavedPage from "@/screens/Saved.vue";
import UserLists from "@/screens/SavedProducts.vue";
import UserLiked from "@/screens/LikedLists.vue";
import LandingPage from "../screens/LandingPage.vue";
import OffersPage from "../screens/Offers.vue";
import HowDoesItWorkPage from "../screens/HowDoesItWork.vue";
import MembershipPage from "../screens/Membership.vue";
import CustomerService from "@/screens/CustomerService.vue";
import ListDetail from "@/screens/ListDetail.vue";
import PickupPoints from "@/screens/PickupPoints.vue";
import Recipes from "@/screens/Recipes.vue";
import RecipeDetail from "@/screens/RecipeDetail.vue";
import CreateRecipe from "@/screens/CreateRecipe.vue";
/* * Define application routes.
 */
const routes = [
  {
    path: "/",
    component: LandingPage,
    meta: {
      title: "Checkout",
    },
  },
  {
    path: "/checkout",
    component: CheckoutPage,
    name: "checkout",
    beforeEnter: [checkCheckoutPage],
    meta: {
      title: "Checkout",
      requiresAuth: true,
      mainDrawer: false,
      hideButtons: true,
    },
  },
  {
    path: "/howDoesItWork",
    component: HowDoesItWorkPage,
    name: "howDoesItWork",
  },
  {
    path: "/membership",
    component: MembershipPage,
    name: "membership",
  },
  {
    path: "/customerService",
    component: CustomerService,
    name: "customerService",
  },
  {
    path: "/anvandarvillkor",
    component: UserTerms,
    name: "userterms",
    meta: { title: "Användarvillkor" },
  },
  {
    path: "/integritetspolicy",
    component: PrivacyPolicy,
    name: "privacypolicy",
    meta: { title: "Integritetspolicy" },
  },
  {
    path: "/handla",
    component: routerView,

    children: [
      { path: "", name: "handla", component: ProductGrid },
      { path: "offers", name: "offers", component: OffersPage },
      {
        path: "product/:id",
        name: "ProductDetail",
        props: true,
        components: {
          default: ProductDetailsSingle,
          dialog: ProductDetailsModal,
        },
        beforeEnter: [keepDefaultView],
      },
      {
        path: "banner/:id",
        name: "banner",
        component: BannerProducts,
        props: true,
      },
      {
        path: "text-banner/:id",
        name: "textBanner",
        component: FeaturedProductsProducts,
        props: true,
      },
      {
        path: "search",
        name: "search",
        component: SearchResultsProducts,
        props: true,
      },
      {
        path: "category/:categoryName/:subcategoryName?",
        name: "category",
        component: CategoryProducts,
        props: true,
      },
      {
        path: "signin",
        component: SignInPage,
        name: "signin",
        meta: { title: "Sign In" },
      },
      {
        path: "signup",
        component: SignUpPage,
        name: "signup",
        meta: { title: "Sign Up" },
      },

      {
        path: "orders",
        component: OrdersPage,
        name: "orders",
        meta: { title: "orders", requiresAuth: true },
      },
      {
        path: "order/:id",
        component: OrderPage,
        name: "order",
        meta: { title: "order" },
      },
      {
        path: "cart",
        component: CartPage,
        name: "cart",
        meta: { title: "Cart" },
      },

      {
        path: "categories",
        component: CategoryNavigationScreen,
        name: "categories",
        meta: { title: "Categories" },
      },
      {
        path: "saved",
        component: SavedPage,
        name: "saved",
        meta: { title: "Saved products", requiresAuth: true },
      },
      {
        path: "saved/list/:id",
        component: ListDetail,
        name: "ListDetailSaved",
        meta: { title: "Mina Listor", requiresAuth: true },
      },
      {
        path: "list/:id",
        component: ListDetail,
        name: "ListDetail",
        meta: { title: "Lista" },
      },
      {
        path: "list/:id",
        component: ListDetail,
        name: "ListDetail",
        meta: { title: "Lista" },
      },
      {
        path: "pickuppoints",
        component: PickupPoints,
        name: "pickupPoints",
        meta: { title: "Lista" },
      },
      {
        path: "recipe",
        component: Recipes,
        name: "recipe",
        meta: { title: "Recipes" },
      },
      {
        path: "recipe/:id",
        component: RecipeDetail,
        name: "recipeDetail",
        meta: { title: "Recipe Detail" },
      },
      {
        path: "createRecipe",
        component: CreateRecipe,
        name: "createRecipe",
        meta: { title: "Create Recipe" },
      },
    ],
  },

  // Add other routes as needed
];

const EmptyComponent = {
  render() {
    return null;
  },
};

function checkCheckoutPage(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next
) {
  console.log(store.state);
  console.log(store.state.cart);
  if (
    store.state.cart !== undefined &&
    store.state.cart.productVariants.length > 0 &&
    store.state.cart.userId !== "" &&
    store.state.cart.pickupTime !== null
  ) {
    next();
  } else {
    next({ path: from.path });
  }
}
function keepDefaultView(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) {
  if (from.matched.length && window.innerWidth >= 768) {
    to.matched[1].components!.default = from.matched[1].components!.default;
    to.meta = { dialog: true };
  } else {
    console.log("in this");
    to.meta = { dialog: false };
  }
}
/**
 * Create a new Vue Router instance.
 */
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top

    if (from.name != "ProductDetail" || to.name == "ProductDetail") {
      return { top: 0 };
    }
  },
});

/**
 * Global navigation guard to handle route authorization and redirection.
 *
 * @param {Object} to - Target Route Object being navigated to.
 * @param {Object} from - Current Route being navigated away from.
 * @param {Function} next - Function to resolve the hook.
 */
router.beforeEach((to, from, next) => {
  const isRouteExist = router
    .getRoutes()
    .some(
      (route) => to.matched.length > 0 && route.path === to.matched[0].path
    );

  if (isRouteExist) {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (!store.state.user) {
        next({ path: "/" });
      } else {
        next();
      }
    } else {
      next();
    }
  } else {
    next({ path: "/" });
  }
});

export default router;
