<template>
  <v-card elevation="0" class="ma-2">
    <v-col>
      <AgeRestriction
        v-if="product.minAge > 0"
        :minAge="product.minAge"
        style="position: absolute; top: 2px; left: 2px; z-index: 1000"
      />
      <DiscountPrice
        v-if="product.promotion"
        style="position: absolute; top: 2px; right: 2px; z-index: 1000"
        :promotion="product.promotion"
        :isWeight="product.weightProduct"
      />

      <v-container @click="handleProductClick(product)" class="hover">
        <v-img
          v-if="product.thumbnail"
          class="text-center"
          :src="product.thumbnail.url"
          :alt="product.thumbnail.alt"
          height="80"
        />
        <v-progress-circular
          v-if="!product.thumbnail"
          color="blue"
          height="10"
          indeterminate
        />
      </v-container>

      <v-row
        align="start"
        justify="space-between"
        class="pa-2 justify-space-between"
        style="height: 170px"
      >
        <v-col class="pa-0" sm="12">
          <p class="bold-1">{{ product.name }}</p>
          <p class="body-4">
            {{ product.manufacturer + " " + product.displayWeight }}
          </p>
          <div
            class="bold-2"
            v-if="product.weightProduct && product.weightInKg != null"
          >
            <p
              v-if="product.promotion && product.promotion.minQuantity == 1"
              style="color: #c94631"
            >
              {{
                "ca. " +
                (product.promotion.discountValue * product.weightInKg).toFixed(
                  2
                ) +
                " kr/st"
              }}
            </p>
            <p v-else>
              {{
                "ca. " +
                (product.price * product.weightInKg).toFixed(2) +
                " kr/st"
              }}
            </p>
          </div>
          <p v-else class="body-4">
            {{
              $t("comparison_price") +
              " " +
              product.comparePrice +
              " " +
              product.compareUnit
            }}
          </p>
        </v-col>

        <!-- Display the promotional price if it exists, otherwise display the regular price -->
        <v-col class="pa-0 mb-2" cols="12" justify="end" align-self="end">
          <div v-if="product.promotion">
            <div v-if="product.promotion.minQuantity == 1">
              <p class="bold-1" style="color: #c94631">
                <span class="bold-h4">
                  {{ product.promotion.discountValue.toFixed(2) }}</span
                >
                {{
                  "/" +
                  (product.weightProduct ? "kg" : $t("piece_abbreviation"))
                }}
              </p>
              <p class="body-4" :style="{ textDecoration: 'line-through' }">
                <span> {{ product.price }}</span>
                {{
                  "/" +
                  (product.weightProduct ? "kg" : $t("piece_abbreviation"))
                }}
              </p>
            </div>
            <p class="bold-1" v-else>
              <span class="font-weight-bold text-h6"> {{ product.price }}</span>
              {{
                "/" + (product.weightProduct ? "kg" : $t("piece_abbreviation"))
              }}
            </p>
          </div>
          <p class="bold-1" v-else>
            <span class="font-weight-bold text-h6"> {{ product.price }}</span>
            {{
              "/" + (product.weightProduct ? "kg" : $t("piece_abbreviation"))
            }}
          </p>
        </v-col>
      </v-row>

      <ProductButton :product="product"></ProductButton>
    </v-col>
  </v-card>
</template>
<script>
import { mapActions, mapState } from "vuex";
import DiscountIcon from "../assets/svg/discount.vue";
import ProductButton from "./ProductButton.vue";
import DiscountPrice from "./DiscountPrice.vue";
import AgeRestriction from "./AgeRestriction.vue";
export default {
  name: "ProductCard",
  components: {
    DiscountIcon,
    AgeRestriction,
    DiscountPrice,
    ProductButton,
  },
  props: {
    replace: {
      type: Boolean,
      default: false, // Default to plus icon
    },
    product: {
      required: true,
    },
  },
  computed: {},

  methods: {
    handleProductClick(product) {
      console.log("click product", product);
      this.replace == true
        ? this.$router.replace({
            name: "ProductDetail",
            params: { id: product._id },
          })
        : this.$router.push({
            name: "ProductDetail",
            params: { id: product._id },
          });
    },
  },
};
</script>
<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.input {
  width: 100%;
  color: white;
  border: 1px solid transparent;
  text-align: center;
}
.input:focus {
  outline: none !important;
  border: 1px solid white;
  border-radius: 2px;
}
.hover {
  cursor: pointer;
}
</style>
