<template>
  <div>
    <v-dialog v-model="show" width="500" @click:outside="decline">
      <v-card>
        <v-card-title>
          <p class="bold-h3">{{ title }}</p>
        </v-card-title>
        <v-card-text>
          <p class="body-2">{{ body }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            variant="text"
            @click="decline"
            color="primary"
            class="button text-none"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            class="button text-none"
            color="error"
            variant="text"
            :loading="loading"
            @click="remove()"
          >
            {{ $t("delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    body: {
      type: String,
      default: "",
    },
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    decline() {
      console.log("this show");
      this.show = false;
    },
    remove() {
      console.log("removing");
      this.$emit("remove", () => this.decline());
    },
  },
};
</script>
