<template>
  <LoginModal :visible="loginModal" @close="loginModal = false"></LoginModal>
  <v-btn
    class="rounded-pill"
    icon
    :size="size"
    @click="likeRecipe(recipe)"
    v-if="!(user != null && user._id == recipe.userId)"
  >
    <v-icon color="error">{{
      liked ? "mdi-heart" : "mdi-heart-outline"
    }}</v-icon>
  </v-btn>
</template>
<script>
import LoginModal from "./LoginModal.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "LikedButton",
  props: {
    size: {
      type: String,
      default: "small",
    },
    recipe: {
      type: Object,
      required: true,
    },
  },
  components: {
    LoginModal,
  },

  data() {
    return {
      loginModal: false,
    };
  },
  async mounted() {},
  watch: {},
  computed: {
    ...mapState(["user"]),
    liked() {
      return this.user != null
        ? this.user.likedRecipes.includes(this.recipe._id)
        : false;
    },
  },
  methods: {
    ...mapActions(["likeRecipeUser"]),
    async likeRecipe() {
      console.log(this.user);
      if (!this.user) {
        this.loginModal = true;
      } else {
        await this.likeRecipeUser({
          recipeId: this.recipe._id,
          like: !this.liked,
        });
      }
    },
  },
};
</script>
