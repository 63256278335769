<template>
  <v-app>
    <v-main>
      <!-- Back Arrow Icon -->
      <v-btn icon class="back-button" @click="goBack">
        <v-icon size="36">mdi-arrow-left</v-icon>
      </v-btn>
      <v-container class="main-container">
        <div class="logo-container">
          <v-img
            src="../../src/assets/images/ns_white.png"
            class="header-image"
            contain
            alt="Nearbystore Logo"
          ></v-img>
        </div>
        <v-container class="login-container">
          <!-- Sign In Form -->
          <form @submit.prevent="signIn" class="signin-form">
            <div class="form-group">
              <label for="email">Email</label>
              <input id="email" v-model="email" type="email" required />
            </div>
            <div class="form-group">
              <label for="password">Password</label>
              <input
                id="password"
                v-model="password"
                type="password"
                required
              />
            </div>
            <button type="submit" class="login-button">Sign In</button>
          </form>
        </v-container>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import UserAPI from "@/api/UserAPI";
import { mapActions } from "vuex";

export default {
  name: "SignInPage",
  data() {
    return {
      email: this.$route.query.email || "",
      password: "",
    };
  },
  methods: {
    ...mapActions(["userLoggedIn"]),
    goBack() {
      this.$router.go(-1);
    },
    async signIn() {
      try {
        const { user, token } = await UserAPI.signInWithEmail(
          this.email,
          this.password
        );
        this.userLoggedIn({ user, token });
        console.log("User signed in:", user);
        this.redirectAfterLogin();
      } catch (error) {
        console.error("Error signing in:", error.message);
        alert(error.message);
      }
    },
    redirectAfterLogin() {
      const redirectTo = this.$route.query.redirectTo || "/";
      this.$router.push(redirectTo);
    },
  },
};
</script>

<style scoped>
.v-main {
  background-color: #003a70;
}

.main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-container {
  max-width: 500px;
  padding: 20px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.logo-container {
  margin-bottom: 100px;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.header-image {
  max-width: 500px;
  margin: auto 10px auto 40px;
}

.form-group {
  margin-bottom: 1rem;
}

.signin-form input {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
}

.login-button {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: #333;
  color: #fff;
  border: none;
  cursor: pointer;
}

.login-button:hover {
  background-color: #555;
}

.back-button {
  position: fixed;
  top: 10px;
  left: 10px;
  color: white;
  background-color: transparent;
}
</style>
